<template>
  <v-app id="body">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" align="center" justify="center">
            <v-img src="@/assets/img/onoff.png" contain max-width="200"></v-img>
            <v-progress-linear
              indeterminate
              color="green_onOff"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="snackbar" timeout="2000" top :color="color">
        <center>{{ text }}</center>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<script>
import authenticationServices from "@/Services/authenticationServices.js";
export default {
  name: "Logout",
  data() {
    return {
      text: "",
      snackbar: "",
      color: "",
      user_id: "",
      token: "",
    };
  },
  created() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    this.logout();
  },
  methods: {
    logout() {
      var config = {
        headers: {
          Authorization: this.token,
        },
        params: { user_id: this.user_id },
      };
      authenticationServices
        .logout(config)
        .then((response) => {
          if (response.data.status) {
            this.color = "success";
            this.text = "Sesión cerrada";
            this.snackbar = true;
            setTimeout(() => {
              localStorage.clear();
              this.$router.push("/login");
            }, 1000);
          } else if (response.data.message == "unauthenticated") {
             this.color = "success";
            this.text = "Sesión cerrada";
            this.snackbar = true;       
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            setTimeout(() => {
              this.$router.push("/login");
            }, 1000);
          }
        })
        .catch((error) => {
          this.text = error;
          this.color = "red";
          this.snackbar = true;
        });
    },
  },
};
</script>
